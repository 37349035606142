/* eslint-disable import/exports-last */
/* eslint-disable max-lines */
import { string, object, boolean, number, array } from "yup";
import {
  Iso3166Alpha3Country,
  PhoneLabel,
  BusinessStructure,
  BusinessTitle,
} from "@bay1/sdk/generated/graphql";
import { USStatesAndTerritories } from "@bay1/data";

export const MIN_STRING_DEFAULT = 2;

const MAX_STRING_DEFAULT = 50;

export const MAX_SUFFIX_OR_TITLE = 10;
export const MAX_MIDDLE_NAME = 50;

const FORMATTED_PHONE_NUMBER = 12;
const MAX_PHONE_EXTENSION = 6;

// https://owasp.org/www-community/OWASP_Validation_Regex_Repository
// eslint-disable-next-line prefer-named-capture-group, security/detect-unsafe-regex, unicorn/no-unsafe-regex, require-unicode-regexp, regexp/prefer-named-capture-group
export const US_POSTAL_CODE_REGEX = new RegExp(/^\d{5}(-\d{4})?$/, "u");
export const MIN_US_POSTAL_CODE = 5;
export const MAX_US_POSTAL_CODE = 10;

export const COUNTRY_CODE_LENGTH = 3;
export const US_STATE_CODE_LENGTH = 2;

export const MIN_STREET_ADDRESS = 3;
export const MAX_STREET_ADDRESS = 255;

export const MIN_LOCALITY = 2;
export const MAX_LOCALITY = 255;

const MONTH_LENGTH = 2;
const DAY_LENGTH = 2;
const NUM_MONTHS = 12;

const MAX_EXTERNAL_ID = 255;

const EIN_LENGTH = 10;
const MAX_WEBSITE_LENGTH = 255;

const MIN_BENEFICIAL_OWNER_PERCENTAGE_OWNERSHIP = 25;
const MAX_PERCENTAGE_OWNERSHIP = 100;

const MIN_BUSINESS_PERSON_YEARS_OF_AGE = 21;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MAX_AMOUNT_VALUE = (Number.MAX_SAFE_INTEGER + 1) / 128 - 1;
export const MIN_AMOUNT_VALUE = 0.01;

// https://owasp.org/www-community/OWASP_Validation_Regex_Repository
// eslint-disable-next-line require-unicode-regexp
const US_SSN_REGEX = new RegExp(/^\d{3}-\d{2}-\d{4}$/, "u");

const currentYear = new Date().getFullYear();

// eslint-disable-next-line no-warning-comments
// TODO: Look at superstruct instead
export const minMaxString = (
  min = MIN_STRING_DEFAULT,
  max = MAX_STRING_DEFAULT,
) =>
  string()
    .min(min, `Must be at least ${min} characters`)
    .max(max, `Maximum length: ${max}`);

export const nameSchema = object()
  .required()
  .shape({
    familyName: minMaxString().required("Required"),
    givenName: minMaxString().required("Required"),
    middleName: minMaxString(1, MAX_MIDDLE_NAME).notRequired(),

    suffix: minMaxString(MIN_STRING_DEFAULT, MAX_SUFFIX_OR_TITLE).notRequired(),

    title: minMaxString(MIN_STRING_DEFAULT, MAX_SUFFIX_OR_TITLE).notRequired(),
  });

export const countryCodeAlpha3 = string()
  .length(COUNTRY_CODE_LENGTH, "Must be a valid Country Code.")
  .default(Iso3166Alpha3Country.USA);

export const addressSchema = object()
  .required()
  .shape({
    countryCodeAlpha3: countryCodeAlpha3.required("Required"),

    extendedAddress: minMaxString(1, MAX_STREET_ADDRESS).notRequired(),

    locality: minMaxString(MIN_LOCALITY, MAX_LOCALITY).required("Required"),

    // Note: This is scoped to US only
    postalCode: minMaxString(MIN_US_POSTAL_CODE, MAX_US_POSTAL_CODE)
      .required("Required")
      .matches(US_POSTAL_CODE_REGEX, "Must be a valid US zip code."),

    // This is scoped to US only
    region: string()
      .required()
      .length(US_STATE_CODE_LENGTH, "Must be 2-letter state code")
      .oneOf(
        Object.values(USStatesAndTerritories),
        "Must be a valid US State or Territory.",
      ),

    streetAddress: minMaxString(
      MIN_STREET_ADDRESS,
      MAX_STREET_ADDRESS,
    ).required("Required"),
  });

const monthSchema = string()
  .length(MONTH_LENGTH, "Invalid Month")
  .required("Required")
  .test("dateOfBirthMonth", "Invalid Month", (value, { createError }) => {
    const valueAsNumber = Number(value);

    if (
      !Number.isNaN(valueAsNumber) &&
      valueAsNumber >= 1 &&
      valueAsNumber <= NUM_MONTHS
    ) {
      return true;
    }

    return createError({ message: "Invalid Month" });
  });

const daySchema = string()
  .length(DAY_LENGTH, "Invalid Day")
  .required("Required")
  .test("dateOfBirthDay", "Invalid Day", (value, { createError }) => {
    const valueAsNumber = Number(value);

    if (
      !Number.isNaN(valueAsNumber) &&
      valueAsNumber >= 1 &&
      // eslint-disable-next-line no-warning-comments
      // TODO: This needs to be contextual
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      valueAsNumber <= 31
    ) {
      return true;
    }

    return createError({ message: "Invalid Day" });
  });

const yearSchema = string()
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  .length(4, "Invalid Year")
  .required("Required");

const dateOfBirthYearSchema = (minYearsOfAge = 13, maxYearsOfAge = 125) =>
  yearSchema.test(
    "dateOfBirthYear",
    "Invalid Year",
    (value, { createError }) => {
      const valueAsNumber = Number(value);

      if (
        !Number.isNaN(valueAsNumber) &&
        valueAsNumber >= currentYear - maxYearsOfAge &&
        valueAsNumber <= currentYear - minYearsOfAge
      ) {
        return true;
      }

      return createError({ message: "Invalid Year" });
    },
  );

const dateOfBirthSchema = object().required().shape({
  month: monthSchema,
  day: daySchema,

  year: dateOfBirthYearSchema(),
});

const businessPersonDateOfBirthSchema = object()
  .required()
  .shape({
    month: monthSchema,
    day: daySchema,

    year: dateOfBirthYearSchema(MIN_BUSINESS_PERSON_YEARS_OF_AGE),
  });

const phoneNumberSchema = object()
  .required()
  .shape({
    countryCode: minMaxString(1, COUNTRY_CODE_LENGTH).required().default("1"),

    number: string()
      .length(FORMATTED_PHONE_NUMBER, "A valid phone number is required")
      .required("Required"),

    extension: string().max(MAX_PHONE_EXTENSION).notRequired(),

    label: string().oneOf(Object.values(PhoneLabel)).default(PhoneLabel.HOME),
  });

export const createAccountHolderValidationSchema = object()
  .required()
  .shape({
    name: nameSchema,

    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,

    dateOfBirth: dateOfBirthSchema,

    externalId: string().notRequired().max(MAX_EXTERNAL_ID),

    identificationDocument: object()
      .required()
      .shape({
        socialSecurityNumber: object()
          .required()
          .shape({
            countryCodeAlpha3: string()
              .required("Required")
              .length(COUNTRY_CODE_LENGTH)
              .default("USA"),

            number: string()
              .required("A valid US Social Security Number is required.")
              .matches(
                US_SSN_REGEX,
                "A valid US Social Security Number is required.",
              ),
          }),
      }),
  });

export const updateAccountHolderValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,
  });

export const updateAssociatedPersonAccountHolderValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("A valid email address is required")
      .required("Required"),

    phoneNumber: phoneNumberSchema,

    homeAddress: addressSchema,
  });

export const updateBusinessAccountHolderValidationSchema = object()
  .required()
  .shape({
    website: string()
      .required("A valid website is required")
      .max(MAX_WEBSITE_LENGTH),

    phoneNumber: phoneNumberSchema,

    billingAddress: addressSchema,
  });

const businessNameSchema = object()
  .required()
  .shape({
    legalBusinessName: minMaxString().required("Required"),
    doingBusinessAsName: minMaxString(),
  });

const ultimateBeneficialOwnerSchema = object().shape({
  name: nameSchema,

  email: string()
    .email("A valid email address is required")
    .required("Required"),

  dateOfBirth: businessPersonDateOfBirthSchema,

  phoneNumber: phoneNumberSchema,

  homeAddress: addressSchema,

  identificationDocument: object()
    .required()
    .shape({
      socialSecurityNumber: object()
        .required()
        .shape({
          countryCodeAlpha3: string()
            .required("Required")
            .length(COUNTRY_CODE_LENGTH)
            .default("USA"),

          number: string()
            .required("A valid US Social Security Number is required.")
            .matches(
              US_SSN_REGEX,
              "A valid US Social Security Number is required.",
            ),
        }),
    }),

  percentageOwnership: number()
    .typeError("Invalid Percentage")
    .required("Required")
    .integer()
    .min(MIN_BENEFICIAL_OWNER_PERCENTAGE_OWNERSHIP)
    .max(MAX_PERCENTAGE_OWNERSHIP),
});

const businessProfileSchema = object()
  .required()
  .shape({
    name: businessNameSchema,

    identificationDocument: object().shape({
      employerIdentificationNumber: object()
        .required()
        .shape({
          number: string()
            .required("Required")
            .length(EIN_LENGTH, "A valid EIN is required"),

          countryCodeAlpha3: string()
            .required("Required")
            .length(COUNTRY_CODE_LENGTH)
            .default("USA"),
        }),
    }),

    businessType: string()
      .notRequired()
      .oneOf(Object.values(BusinessStructure)),

    phoneNumber: phoneNumberSchema,

    website: string().max(MAX_WEBSITE_LENGTH),

    billingAddress: addressSchema,

    ultimateBeneficialOwners: array().of(ultimateBeneficialOwnerSchema),
  });

const primaryAuthorizedPersonSchema = object()
  .required()
  .shape({
    name: nameSchema,

    email: string()
      .email("A valid email address is required")
      .required("Required"),

    authorizingPersonTitle: string()
      .notRequired()
      .oneOf(Object.values(BusinessTitle)),

    dateOfBirth: businessPersonDateOfBirthSchema,

    phoneNumber: phoneNumberSchema,

    homeAddress: addressSchema,

    identificationDocument: object()
      .required()
      .shape({
        socialSecurityNumber: object()
          .required()
          .shape({
            countryCodeAlpha3: string()
              .required("Required")
              .length(COUNTRY_CODE_LENGTH)
              .default("USA"),

            number: string()
              .required("A valid US Social Security Number is required.")
              .matches(
                US_SSN_REGEX,
                "A valid US Social Security Number is required.",
              ),
          }),
      }),

    percentageOwnership: number()
      .typeError("Invalid percentage.")
      .integer()
      // eslint-disable-next-line no-template-curly-in-string
      .min(0, "Must be greater than or equal to ${min}")
      // eslint-disable-next-line no-template-curly-in-string
      .max(MAX_PERCENTAGE_OWNERSHIP, "Must be less than or equal to ${max}"),
  });

export const createBusinessAccountHolderValidationSchema = object()
  .required()
  .shape({
    businessProfile: businessProfileSchema,
    primaryAuthorizedPerson: primaryAuthorizedPersonSchema,
    externalId: string().notRequired().max(MAX_EXTERNAL_ID),
  });

export const emailNewsletterValidationSchema = object()
  .required()
  .shape({
    email: string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
  });

export const emailValidationSchema = string()
  .email("Please enter a valid email address")
  .required("Please enter a valid email address");

export const categorySchema = object().shape({
  line1: minMaxString().notRequired(),
  line2: minMaxString().notRequired(),
  line3: minMaxString().notRequired(),
  line4: minMaxString().notRequired(),
  line5: minMaxString().notRequired(),
  line6: minMaxString().notRequired(),
  line7: minMaxString().notRequired(),
  line8: minMaxString().notRequired(),
  line9: minMaxString().notRequired(),
  line10: minMaxString().notRequired(),
});

export const reissuePaymentCardSchema = object().shape({
  originalPaymentCardId: string().required(),

  options: object().shape({
    activateOnCreate: boolean().required(),

    expirationDate: object().shape({
      month: string().required(),
      year: string().required(),
    }),

    reissueFeatures: object().shape({
      copyPin: boolean().required(),
      copyNumber: boolean().required(),
    }),
  }),
});

export const addWebhookNotificationTargetSchema = object().shape({
  name: string().required("Required"),
  uri: string().url("Must be a valid URI").required("Required"),

  subscriptions: array().test("subscriptions", (value, { createError }) => {
    const subscriptions = value;
    const notifications = subscriptions
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ?.filter((sub) => sub.value)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      .map((sub) => sub.value);
    if (notifications && notifications.length > 0) {
      return true;
    }
    return createError({ message: "Please Select at least one event" });
  }),
});

export const issueFinancialAccountSchema = object().shape({
  name: string().required("Required"),
  applicationId: string().required("Required"),
});
